import { isArray } from 'lodash';
import { useState, useEffect } from 'react';
import { map } from 'rxjs/operators';

import { RobotIdentityData } from '@sb/feathers-types';

import { getService } from '../utils';

export function onLoadRobotIdentity(
  subscribe: (flags: RobotIdentityData) => void,
): () => void {
  const robotIdentityService = getService('robotIdentity')();

  const sub = robotIdentityService
    .watch()
    .find({ query: {} })
    .pipe(
      map((data) => {
        const parsedResponse = RobotIdentityData.safeParse(
          isArray(data) ? data[0] : data,
        );

        if (parsedResponse.success) {
          return parsedResponse.data;
        }

        return RobotIdentityData.parse({
          robotId: '',
          alias: '',
          cloudflareTunnelId: '',
          applicationUrl: '',
        });
      }),
    )
    .subscribe(subscribe);

  return () => sub.unsubscribe();
}

/**
 * Hook to listen for robot identity from Marvin.
 */
export const useRobotIdentity = (): RobotIdentityData | null => {
  const [robotIdentity, setRobotIdentity] = useState<RobotIdentityData | null>(
    null,
  );

  useEffect(() => {
    return onLoadRobotIdentity(setRobotIdentity);
  }, []);

  return robotIdentity;
};

export async function updateRobotIdentity<K extends keyof RobotIdentityData>(
  key: K,
  value: RobotIdentityData[K],
): Promise<void> {
  const robotIdentityService = getService('robotIdentity')();

  await robotIdentityService.patch('1', {
    [key]: value,
  });
}
